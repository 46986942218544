import React from 'react';

const OvalVideoEmbed = () => {
  const videoId = 'QvhmhWcYEMM';

  return (
    <div className="relative w-full md:w-10/12 h-0 pb-[56.25%] overflow-hidden">
      <div 
        className="absolute inset-0 bg-gradient-to-r from-[#6a9ad4] to-[#3b79be]"
        style={{ 
          clipPath: 'polygon(0% 10%, 100% 10%, 100% 80%, 0% 100%)',
          borderRadius: '20px',
          padding: '4px',
        }}
      >
        <div 
          className="w-full h-full bg-[#3b79be] bg-opacity-20 relative overflow-hidden"
          style={{ 
            clipPath: 'polygon(0% 10%, 100% 10%, 100% 80%, 0% 100%)',
            borderRadius: '20px',
          }}
        >
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?playsinline=1`}
            className="absolute top-0 left-0 w-full h-full"
            style={{
              clipPath: 'polygon(0% 10%, 100% 10%, 100% 80%, 0% 100%)',
              borderRadius: '8px',
            }}
            allow="encrypted-media"
            allowFullScreen
            title="YouTube video player"
          />
        </div>
      </div>
    </div>
  );
};

export default OvalVideoEmbed;