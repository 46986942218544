import React, { useState, useEffect } from 'react';

const BestTextEffects = ({ text, effect = 'typewriter', className }) => {
  const [displayText, setDisplayText] = useState('');
  const [color, setColor] = useState('white'); // Changed default color to white

  useEffect(() => {
    let interval;
    let frame;

    switch (effect) {
      case 'typewriter':
        let i = 0;
        interval = setInterval(() => {
          if (i <= text.length) {
            setDisplayText(text.slice(0, i));
            i++;
          } else {
            clearInterval(interval);
          }
        }, 100);
        break;

      case 'fadein':
        setDisplayText(text);
        break;

      case 'colorchange':
        setDisplayText(text);
        const colors = ['#280137', '#6a9ad4', '#535f8d', '#3b79be'];
        let colorIndex = 0;
        interval = setInterval(() => {
          setColor(colors[colorIndex % colors.length]);
          colorIndex++;
        }, 1000);
        break;

      case 'scramble':
        const finalText = text;
        let currentText = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let iteration = 0;

        const scramble = () => {
          currentText = finalText
            .split('')
            .map((letter, index) => {
              if (index < iteration) {
                return finalText[index];
              }
              return possible[Math.floor(Math.random() * possible.length)];
            })
            .join('');

          setDisplayText(currentText);
          iteration += 1 / 3;

          if (iteration < finalText.length) {
            frame = requestAnimationFrame(scramble);
          }
        };

        frame = requestAnimationFrame(scramble);
        break;

      case 'highlight':
        setDisplayText(text);
        break;

      case 'bounce':
        setDisplayText(text);
        let bounceIndex = 0;
        interval = setInterval(() => {
          setDisplayText(prev => 
            prev.split('').map((char, i) => 
              i === bounceIndex ? char.toUpperCase() : char.toLowerCase()
            ).join('')
          );
          bounceIndex = (bounceIndex + 1) % text.length;
        }, 200);
        break;

        case 'scramble-typewriter':
            // Use existing variables from outer scope
            finalText = text;
            currentText = '';
            possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            iteration = 0;
    
            const scrambleTypewriter = () => {
              if (iteration < finalText.length * 2) {
                currentText = finalText
                  .split('')
                  .map((letter, index) => {
                    if (index < iteration / 2) {
                      return finalText[index];
                    }
                    return possible[Math.floor(Math.random() * possible.length)];
                  })
                  .join('');
    
                setDisplayText(currentText);
                iteration++;
                frame = requestAnimationFrame(scrambleTypewriter);
              }
            };
    
            frame = requestAnimationFrame(scrambleTypewriter);
            break;
    
          default:
            setDisplayText(text);
        }
    
        return () => {
          clearInterval(interval);
          cancelAnimationFrame(frame);
        };
      }, [text, effect]);
    
      return (
        <div className={`text-effect ${effect} ${className}`} style={{ color }}>
          {displayText}
        </div>
      );
    };
    
    export default BestTextEffects;