import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { Circle, Clock, Moon, Sun, Menu, Eclipse } from 'lucide-react';

const MobileMenu = ({ isOpen, toggleMenu }) => (
  <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} absolute top-full left-0 right-0 bg-[#0f2137] z-50`}>
    <div className="flex flex-col items-center py-4 space-y-4">
      <NavItem href="/">Home</NavItem>
      <NavItem href="/learn-more">Learn More</NavItem>
      <NavItem href="/pricing">Pricing</NavItem>
      <NavItem href="/announcements">Announcements</NavItem>
      <NavItem href="/roadmap">Roadmap</NavItem>
    </div>
  </div>
);

const NechepsoText = ({ className }) => (
  <span className={`text-white ${className}`}>Nechepso</span>
);

const NavItem = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-sm uppercase tracking-wider hover:text-[#6a9ad4] transition-colors"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const NechepsoLogo = () => (
  <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-4">
    <span className="font-outhill text-white">NechepsO</span>
  </h1>
);

const Button = ({ className, children, variant, ...props }) => (
  <button
    className={`px-4 py-2 rounded transition-colors duration-300 ${
      variant === 'outline'
        ? 'bg-transparent border border-current hover:bg-opacity-10'
        : 'bg-gradient-to-r from-[#6a9ad4] to-[#a6b4c4] hover:from-[#7baae4] hover:to-[#b6c4d4]'
    } ${className}`}
    {...props}
  >
    {children}
  </button>
);

const Card = ({ className, children }) => (
  <div className={`bg-white rounded-lg shadow-md ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children }) => <div className="p-6">{children}</div>;
const CardTitle = ({ className, children }) => <h2 className={`text-xl font-semibold ${className}`}>{children}</h2>;
const CardDescription = ({ className, children }) => <p className={`mt-2 ${className}`}>{children}</p>;
const CardContent = ({ children }) => <div className="p-6 pt-0">{children}</div>;
const CardFooter = ({ children }) => <div className="p-6 bg-gray-50 rounded-b-lg mt-auto">{children}</div>;

const PricingCard = ({ title, price, description, features, description2,ctaText, popular = false, disabled = false }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  const getGlyph = (title) => {
    switch (title) {
      case "Limited Time Presale":
        return <Clock size={24} className="text-black" />;
      case "Monthly Plan":
        return <Moon size={24} className="text-[#6a9ad4]" />;
      case "Biannual Plan":
        return <Eclipse size={24} className="text-[#F87F1C]"/>;
      case "Annual Plan":
        return <Sun size={24} className="text-yellow-400" />;
      default:
        return null;
    }
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 }
      }}
      transition={{ duration: 0.5 }}
    >
      <Card className={`flex flex-col h-full relative ${
        popular ? 'border-[#6a9ad4] border-2' : ''
      } ${
        disabled ? 'bg-gray-100 opacity-75' : ''
      }`}>
        {disabled && (
          <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-yellow-400 text-[#280137] font-bold py-2 px-4 rotate-[-45deg] w-[150%] text-center">
              Starting at Launch
            </div>
          </div>
        )}
        <div className="flex-grow">
          <CardHeader>
            <div className="flex justify-between items-center">
              <CardTitle className="text-2xl font-bold text-[#280137]">{title}</CardTitle>
              <div className="text-[#6a9ad4]">
                {getGlyph(title)}
              </div>
            </div>
            <CardDescription className="text-[#535f8d]">{description}</CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-4xl font-bold text-[#280137] mb-4">${price}</p>
            <ul className="space-y-2">
              {features.map((feature, index) => (
                <li key={index} className="flex items-start text-[#535f8d]">
                  <Circle className="mr-2 h-2 w-2 flex-shrink-0 fill-current text-[#6a9ad4] mt-1" />
                  <span>{feature}</span>
                </li>
              ))}
            </ul><br />
            <CardDescription className="text-[#535f8d]">{description2}</CardDescription>
          </CardContent>
        </div>
        <CardFooter>
          <Button 
            className={`w-full text-white font-bold border-2 border-black ${
              title === "Limited Time Presale" 
                ? 'bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800' 
                : 'bg-gradient-to-r from-[#6a9ad4] to-[#a6b4c4] hover:from-[#7baae4] hover:to-[#b6c4d4]'
            } ${
              disabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={disabled}
            onClick={() => {
              if (title === "Limited Time Presale") {
                window.open("https://docs.google.com/forms/d/e/1FAIpQLScHhZeKuPONgM1yvd0R5eMzwDYhQReXSYy5g2aRqvehFWbvhQ/viewform?pli=1", "_blank");
              }
            }}
          >
            {ctaText}
          </Button>
        </CardFooter>
      </Card>
    </motion.div>
  );
};

const PricingPage = () => {
  const [scrollY, setScrollY] = useState(0);

  // Metadata logic for page title and description
  useEffect(() => {
    // Set the title of the page
    document.title = "Pricing Plans - Nechepso";

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Nechepso lowers the entry barrier to make it easier for you to take your astrological practice to the next level."
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content =
        "Nechepso lowers the entry barrier to make it easier for you to take your astrological practice to the next level.";
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const parallaxRef = useRef(null);

  useEffect(() => {
    const parallaxEffect = () => {
      const scrollPosition = window.pageYOffset;
      const parallaxElements = parallaxRef.current.querySelectorAll('.parallax');
      parallaxElements.forEach((el) => {
        const speed = el.dataset.speed;
        const yPos = -(scrollPosition * speed);
        el.style.transform = `translateY(${yPos}px)`;
      });
    };
    window.addEventListener('scroll', parallaxEffect);
    return () => window.removeEventListener('scroll', parallaxEffect);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0f2137] via-[#3776c1] to-[#a3c1e4] text-white overflow-hidden">    

      {/* Parallax stars */}
      <div ref={parallaxRef} className="absolute inset-0 overflow-hidden">
        {[...Array(100)].map((_, index) => (
          <motion.div
            key={index}
            className="absolute rounded-full bg-white parallax"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              width: Math.random() * 4 + 1,
              height: Math.random() * 4 + 1,
            }}
            data-speed={Math.random() * 0.5 + 0.2}
            animate={{
              scale: [1, 1.5, 1],
              opacity: [0.7, 1, 0.7],
            }}
            transition={{
              repeat: Infinity,
              duration: Math.random() * 3 + 2,
              ease: "easeInOut",
            }}
          />
        ))}
      </div>

      <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300`}>
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-90' : 'opacity-0'}`}>
          <div className="absolute inset-0 bg-gradient-to-r from-[#a3c1e4] via-[#3776c1] to-[#0f2137]"></div>
        </div>
        <div className={`absolute inset-0 transition-opacity duration-300 ${scrollY > 50 ? 'opacity-100' : 'opacity-0'}`}>
          <div className="absolute inset-0 backdrop-blur-md"></div>
        </div>
        <div className="container mx-auto px-4 py-6 flex justify-between items-center relative z-10">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-center"
          >
            <NechepsoLogo />
          </motion.div>
          <div className="hidden md:flex space-x-8">
            <NavItem href="/">Home</NavItem>
            <NavItem href="/learn-more">Learn More</NavItem>
            <NavItem href="/pricing">Pricing</NavItem>
            <NavItem href="/announcements">Announcements</NavItem>
            <NavItem href="/roadmap">Roadmap</NavItem>
          </div>
          <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Menu className="w-6 h-6 text-white" />
          </button>
        </div>
        <MobileMenu isOpen={isMenuOpen} toggleMenu={() => setIsMenuOpen(!isMenuOpen)} />
      </nav>

      <main className="container mx-auto px-4 pt-40 pb-16">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-5xl md:text-7xl font-bold text-center mb-6 text-[#c5d8ed]"
        >
          Pricing Plans
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-xl md:text-2xl text-center mb-12 max-w-3xl mx-auto text-[#e5eef7]"
        >
          Choose the perfect plan for your astrological journey with <NechepsoText className="font-bold" />.
        </motion.p>

        <div className="grid md:grid-cols-3 gap-8 w-full max-w-6xl mx-auto min-h-[600px]">
          <PricingCard
            title="Monthly Plan"
            price="29.99"
            description="Flexible month-to-month subscription."
            features={[
              "Full access to Nechepso",
              "All updates included with your subscription",
              "Pay as you go",
              "Cancel anytime"
            ]}
            description2="Have high dollar fees from other softwares been too high a barrier to overcome? This plan is for you - with low monthly payments Nechepso lowers the entry barrier to make it easier for you to take your astrological practice to the next level."
            ctaText="Start Monthly Plan"
            disabled={true}
          />
          <PricingCard
            title="Biannual Plan"
            price="164.99"
            description="Only pay twice a year."
            features={[
              "Full access to Nechepso",
              "All updates included with your subscription",
              "One month free (10% off) compared to monthly plan",
            ]}
            description2="Pay less by committing to a six-month plan. Perfect for astrologers who want to minimize their monthly costs while maintaining access to professional-grade software. Get one month free compared to paying monthly."
            ctaText="Start Biannual Plan"
            disabled={true}
          />
          <PricingCard
            title="Annual Plan"
            price="299.99"
            description="Best value for dedicated astrologers."
            features={[
              "Full access to Nechepso",              
              "All updates included with your subscription",
              "Two months free (20% off) compared to monthly plan"
            ]}
            description2="Save 20% off the monthly plan when paid annually. Every penny saved helps you grow your practice."
            ctaText="Start Annual Plan"
            disabled={true}
          />
        </div>

        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="mt-12 text-center relative z-50"
        >
          <p className="text-lg mb-4 text-[#280137] font-bold">Not sure which plan is right for you?</p>
          <button
            className="px-4 py-2 rounded transition-colors duration-300 bg-gradient-to-r from-[#6a9ad4] to-[#a6b4c4] hover:from-[#7baae4] hover:to-[#b6c4d4] text-[#280137] cursor-pointer relative z-50 border border-[#280137]"
            onClick={() => window.location.href = "mailto:themamunditechnologies@gmail.com"}
          >
            Contact Sales
          </button>
        </motion.div>
      </main>

      <footer className="bg-[#2a5688] py-8 relative">
        <div className="container mx-auto px-4 text-center relative z-10">
          <p className="text-[#c5d8ed]">&copy; 2024 Thema Mundi Technologies. All rights reserved.</p>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-8 bg-repeat-x" style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon fill='%23c5d8ed' points='0,100 50,0 100,100'/%3E%3C/svg%3E")`,
          backgroundSize: '50px 100%',
          opacity: 0.2,
        }}></div>
      </footer>
    </div>
  );
};

export default PricingPage;